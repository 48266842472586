import {differenceInCalendarDays, differenceInCalendarMonths, differenceInCalendarWeeks, format, isBefore, isSameDay, isValid, startOfDay} from "date-fns";
import Holidays from "date-holidays";
import {Period} from "../types/GeneralTypes";
import {PeriodType} from "../types/capitalBudgetEnums";

const hd = new Holidays("AU", "NSW")

// Checks if string is a valid date
export function checkValidDate(date: string | Date | null): boolean {
    if (typeof date === 'string') date = new Date(date);
    return isValid(date);
}

// Checks if date one is the same as date two
export function checkDateSame(date: string | number | Date, dateAfter: string | number | Date): boolean {
    if (typeof date === 'string') date = new Date(date);
    if (typeof dateAfter === 'string') dateAfter = new Date(dateAfter);
    return isSameDay(date, dateAfter);
}

// Checks if date one is before date two
export function checkDateBefore(date: string | number | Date, dateAfter: string | number | Date): boolean {
    if (typeof date === 'string') date = new Date(date);
    if (typeof dateAfter === 'string') dateAfter = new Date(dateAfter);
    return isBefore(startOfDay(date), startOfDay(dateAfter));
}

// Checks if date one is same or before date two
export function checkDateSameOrBefore(date: string | number | Date, dateAfter: string | number | Date): boolean {
    if (typeof date === 'string') date = new Date(date);
    if (typeof dateAfter === 'string') dateAfter = new Date(dateAfter);
    return isBefore(startOfDay(date), startOfDay(dateAfter)) || isSameDay(date, dateAfter);
}

// Check date between or same as the last
export function checkDateBetween(date: string | number | Date, before: string | number | Date, after: string | number | Date) {
    if (typeof date === 'string') date = new Date(date);
    if (typeof before === 'string') before = new Date(before);
    if (typeof after === 'string') after = new Date(after);

    return ((isBefore(startOfDay(date), startOfDay(after)) || isSameDay(date, after)) && (isBefore(startOfDay(before), startOfDay(date)) || isSameDay(date, before)))
}


export function checkInPeriod(date: Date | number, period: Period): boolean {
    return checkDateSameOrBefore(date, period.lastDate) && checkDateSameOrBefore(period.startDate, date);
}

// Formats date to string
export function formatDate(date: Date | string | number, dateFormat = 'dd-MM-yyyy'): string {
    try {
        if (!!date && date !== '') {
            if (typeof date === 'string') {
                return format(new Date(date), dateFormat)
            } else {
                return format(date, dateFormat)
            }
        } else {
            return '';
        }
    } catch (e) {
        return 'N/A'
    }
}

// Add days to date
export function addDays(date: Date | string | number, days: number) {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

export function subtractOneMonth(date: string | Date) {
    date = new Date(date);
    const currentMonth = date.getMonth();
    const newDate = new Date(date);

    // Step 1: Set the date to the 1st of the current month
    newDate.setDate(1);

    // Step 2: Subtract one month
    newDate.setMonth(currentMonth - 1);

    // Step 3: Adjust the date if the resulting month has fewer days
    const maxDaysInPrevMonth = new Date(
        newDate.getFullYear(),
        newDate.getMonth() + 1,
        0
    ).getDate();

    newDate.setDate(Math.min(date.getDate(), maxDaysInPrevMonth));

    return newDate;
}


// Date sort comparator
export function dateSortComparator(a: Date | string | number, b: Date | string | number) {
    if (checkDateBefore(a, b)) return -1;
    else if (checkDateBefore(b, a)) return 1;
    else return 0
}

// Find next Day of the Week
export function findDayOfWeek(date: string | Date, dayOfWeek: number): Date {
    date = new Date(date);
    // sets day to Start of the week
    date.setDate(date.getDate() - date.getDay() + 1);
    // finds next day
    date.setDate(date.getDate() + (7 + dayOfWeek - date.getDay()) % 7);
    return date;
}

// Find next Day of the Week
export function findNextDayOfTheWeek(date: string | Date, dayOfWeek: number): Date {
    date = new Date(date);
    date.setDate(date.getDate() + (7 + dayOfWeek - date.getDay()) % 7);
    return date;
}


// finds the last day of the month
export function findlastDayOfMonth(date: string | Date): Date {
    date = new Date(date);
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}

// Find last day of month
export function getLastDayPreviousMonth(date: string | Date, allowSame: boolean = true): Date {
    let newDate = new Date(date);
    // Gets last day of month
    newDate = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0);


    // if newDate is after date and not allow same is not allowed subtract 1 month
    if (((allowSame && checkDateBefore(date, newDate)) || (!allowSame && checkDateSameOrBefore(date, newDate)))) {
        newDate = subtractOneMonth(newDate)
        newDate = new Date(newDate.getFullYear(), newDate.getMonth()+1, 0);
    }

    return newDate
}

// Find last Business Day of Previous month
export function getLastBusinessDayPreviousMonth(date: string | Date, allowSame: boolean = true): Date {
    let newDate = new Date(date);

    // if newDate is after date and not allow same is not allowed subtract 1 month
    if (((allowSame && checkDateBefore(date, newDate)) || (!allowSame && checkDateSameOrBefore(date, newDate)))) {
        newDate = subtractOneMonth(newDate)
        newDate = new Date(newDate.getFullYear(), newDate.getMonth()+1, 0);

        // If weekend revert to Friday
        if (newDate.getDay() === 6) {
            newDate = addDays(newDate, -1);
        } else if (newDate.getDay() === 0) {
            newDate = addDays(newDate, -2)
        }
    }

    return newDate
}

// Checks if day is public holiday and winds back until day is no longer public holiday
function getLastBusDay(date: Date): Date {
    if (hd.isHoliday(date)) {
        date = addDays(date, -1);
        return getLastBusDay(date);
    }
    while (date.getDay() < 1 || date.getDay() > 5) {
        date.setDate(date.getDate() - 1);
    }

    return date;
}

// Finds last Business day of the month
function findLastBusDayMonth(date: string | Date): Date {
    date = new Date(date);

    let lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    lastDate = getLastBusDay(lastDate);

    return lastDate;
}

// Finds the month end of the previous month taking into account weekends and publix holidays
export function findLastMonthEnd(date: Date | string): Date {
    let originalDate = new Date(date);

    // GET LAST DAY OF CURRENT MONTH
    let lastDayCurrent = findLastBusDayMonth(originalDate);

    if (checkDateSameOrBefore(lastDayCurrent, originalDate)) return lastDayCurrent;

    return findLastBusDayMonth(subtractOneMonth(originalDate));
}

// Coverts DMY date format with "=" to a Date string
export function convertDMYDate(date: string) {
    const dateParts = date.split('-');

    return new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]).toDateString()
}

// Generates periods for n weeks and n months
export function generatePeriods(noWeeks: number, noMonths: number, periodStartDate?: Date | number): {base: Period, weeks: Array<Period>, months: Array<Period>} {
    let weeks = [];
    let months = [];

    // GENERATE WEEKS
    for (let w = 0; w < noWeeks + 1; w++) {
        const seed = (periodStartDate) ? new Date(periodStartDate) : new Date();

        seed.setDate(seed.getDate() + (w * 7));

        const startDate = (w !== 0) ?
            findDayOfWeek(seed, 1) :
            new Date(0);

        const lastDate = findDayOfWeek(seed, 7);

        const tooltip = `${(w !== 0) ? `${startDate.toDateString()} - ` : ''}${lastDate.toDateString()}`;

        weeks.push({
            label: (w === 0) ? `Current Week` : `Week ${w}`,
            labelTwo: `(${formatDate(lastDate, 'dd-MMM')})`,
            tooltip,
            startDate: startDate.toDateString(),
            lastDate: lastDate.toDateString(),
            type: PeriodType.WEEK
        })
    }
    // GENERATE MONTHS
    for (let m = 0; m < noMonths + 1; m++) {
        const today = (periodStartDate) ? new Date(periodStartDate) : new Date();

        const firstDate = new Date(today.getFullYear(), today.getMonth(), 1);

        const tempDate =  new Date(new Date(firstDate).setMonth(firstDate.getMonth() + m));

        const startDate = (m !== 0) ? tempDate : new Date(0);

        const lastDate = (m !== 0) ?
            new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0)
            :
            new Date(today.getFullYear(), today.getMonth() + 1, 0)

        const tooltip = `${(m !== 0) ? `${startDate.toDateString()} - ` : ''}${lastDate.toDateString()}`;

        months.push({
            label: (m === 0) ? 'Current Month' : `Month ${m}`,
            labelTwo: `(${formatDate(lastDate, 'dd-MMM')})`,
            tooltip,
            startDate: startDate.toDateString(),
            lastDate: lastDate.toDateString(),
            type: PeriodType.MONTH
        })
    }


    const baseDate = (periodStartDate) ? new Date(periodStartDate) : new Date();

    const base = {
        label: 'Base',
        labelTwo: `(${formatDate(baseDate, 'dd-MMM')})`,
        tooltip: `(${formatDate(baseDate, 'dd-MMM')})`,
        startDate: new Date(0).getTime(),
        lastDate: baseDate.getTime(),
        type: PeriodType.BASE,
        base: true
    }

    return {
        base,
        weeks,
        months
    };
}

// Determines weeks between dates
export function weeksBetweenDates(startingDate: Date | number, laterDate: Date | number) {
    return differenceInCalendarWeeks(new Date(laterDate), new Date(startingDate));
}

// Determines weeks between dates
export function monthsBetweenDates(startingDate: Date | number, laterDate: Date | number) {
    return differenceInCalendarMonths(new Date(laterDate), new Date(startingDate));
}

// Difference in days between dates
export function daysBetweenDates(startDate: Date | number, laterDate: Date | number) {
    return differenceInCalendarDays(new Date(laterDate), new Date(startDate));
}

// Adds months to data adjusts to remove month rollover if end of the month
export function addMonthsToDate(startDate: Date | number, months: number): Date | number {
    const date = new Date(startDate);
    const day = date.getDate();

    date.setMonth(date.getMonth() + months);

    if (date.getDate() !== day) {
        date.setDate(0)
    }

    return date.getTime();
}