import {Formik, FormikProps} from "formik";
import * as Yup from "yup";
import {checkDateSameOrBefore, formatDate} from "../../../utils/DateUtils";
import {addValues} from "../../../utils/mathUtil";
import {fCurrency} from "../../../utils/formatNumber";
import {InputTypes} from "../../../types/InputTypes";
//Components
import {FormInput} from "../../../components";
// MUI
import {Button, Grid, Typography} from "@mui/material";
// Store
import {useAppSelector} from "../../../store/store";
import {forecastedPortfolioMap} from "../../../store/capitalBudget/selectors/portfolioSelector";
import {retrieveGroupedPortfolioWMatChanges} from "../../../store/capitalBudget/selectors/maturitySelector";


type SelldownRepaymentsFormProps = {
    editValues: any | null,
    onClose: () => void,
    submitTransaction: (values: any) => void,
    editTransaction: (values: any) => void
}

const defaultTransactionValues = {
    portfolio: null,
    fund: '',
    amount: 0,
    date: null,
    fundTranche: null
}

export default function SelldownRepaymentsForm({
                                                   editValues,
                                                   onClose,
                                                   submitTransaction,
                                                   editTransaction
                                               }: SelldownRepaymentsFormProps) {

    const portfolio = useAppSelector(state => retrieveGroupedPortfolioWMatChanges(state));
    const portfolioMap = useAppSelector(state => forecastedPortfolioMap(state));

    return (
        <>
            <Formik initialValues={editValues?.id ? {
                ...editValues,
                portfolio: portfolio.find(p => editValues.trancheId === p.tranche_id),
                fund: (editValues.fund || '')
            } : defaultTransactionValues}
                    validationSchema={
                        Yup.object().shape({
                            date: Yup.date()
                                .typeError('Valid Date Required')
                                // .min(new Date(), 'Date must be after today.')
                                .required('Date is required'),
                            amount: Yup.number().min(0).test({
                                name: 'min',
                                exclusive: false,
                                params: {},
                                message: 'Amount invalid. Amount exceeds expected commitment.',
                                test: function (value) {
                                    // Checks if the new amount will exceed expected or later commitment changes
                                    const {fundTranche, date} = this.parent;

                                    if (!fundTranche || !value) return false
                                    let commitment = (!fundTranche?.newTransferDate) ? fundTranche.commitment || 0 : 0;

                                    // adds to commitment if further amounts come in
                                    fundTranche.transfersIn.forEach((t: any) => {
                                        if (checkDateSameOrBefore(t.transferDate, date)) {
                                            commitment = addValues(commitment, t.amount);
                                        }
                                    })

                                    // should counter for all expected commitments to not over transfer out or reducer
                                    fundTranche.transfersOut.forEach((t: any) => {
                                        commitment = addValues(commitment, -t.amount);
                                    })

                                    // should counter for all expected commitments to not over transfer out or reducer
                                    fundTranche.sellRepay.forEach((sr: any) => {
                                            commitment = addValues(commitment, -sr.amount);
                                    })

                                    return value <= commitment;
                                }
                            }).required('Amount Required.'),
                            fund: Yup.string().required('Required.').required('Required.')
                        })
                    }
                    onSubmit={(values: any) => {
                        if (editValues?.id) {
                            editTransaction({
                                id: values.id,
                                trancheId: values.portfolio.tranche_id,
                                fund: values.fund,
                                date: formatDate(values.date, 'yyyy-MM-dd'),
                                amount: values.amount
                            })
                        } else {
                            submitTransaction({
                                trancheId: values.portfolio.tranche_id,
                                fund: values.fund,
                                date: formatDate(values.date, 'yyyy-MM-dd'),
                                amount: values.amount
                            })
                        }
                    }}
            >
                {(props: FormikProps<any>) => {
                    const {
                        handleSubmit,
                        setFieldValue,
                        resetForm,
                        values,
                    } = props;

                    let tranche;
                    let fund;
                    let fundsIn = [];

                    try {

                        // IF portfolio available set funds selectable
                        if (values.portfolio) {
                            tranche = portfolioMap.get(values.portfolio.tranche_id);
                            if (tranche) {
                                for (let key of tranche.keys()) {
                                    fundsIn.push({
                                        value: key,
                                        label: `${key}${(tranche.get(key)?.newTransferDate) ? ` from ${formatDate(tranche.get(key)?.newTransferDate)}` : ''}`
                                    });
                                }
                            }
                        }

                        // If fund selected, set details to show additional data for fund tranche
                        if (values.fund) {
                            if (tranche.get(values.fund)) {
                                fund = tranche.get(values.fund);
                            } else {
                                setFieldValue('fund', '');
                            }
                            if (!values.fundTranche || values.fundTranche.id !== fund.id) {
                                setFieldValue('fundTranche', fund);
                            }
                        }


                    } catch (e) {
                        console.log(e)
                        resetForm();
                    }

                    return (
                        <>
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{p: 2}}>
                                    <FormInput
                                        id='portfolio'
                                        label='Portfolio'
                                        fieldType={InputTypes.SEARCH}
                                        values={portfolio}
                                        labelFunc={(option) => `${option.tranche_id} - ${option.borrower}`}
                                        layout={{xs: 12, md: 12, lg: 12}}
                                    />
                                    <FormInput
                                        id='fund'
                                        label='Fund'
                                        fieldType={InputTypes.SELECTION}
                                        layout={{xs: 12, md: 12, lg: 12}}

                                        values={fundsIn}
                                        disabled={!tranche}
                                    />
                                    {/* Show Fund Details */}
                                    {fund &&
                                        <Grid container item xs={12}>
                                            <Grid item xs={12} sx={{px: 2, py: 1}}>
                                                <Typography variant='h4'
                                                            sx={{color: 'primary.main'}}><b>Loan:</b></Typography>
                                            </Grid>
                                            <Grid container item xs={12} sx={{px: 5, py: 1}} direction='row'>
                                                <Typography variant='subtitle1' sx={{color: 'primary.main'}}><b>Start
                                                    Date:</b></Typography><Typography variant='body1' sx={{
                                                pl: 5,
                                                fontSize: 16
                                            }}>{new Date(fund.start_date).toDateString()}</Typography>
                                            </Grid>
                                            <Grid container item xs={12} sx={{px: 5, py: 1}} direction='row'>
                                                <Typography variant='subtitle1' sx={{color: 'primary.main'}}><b>Maturity
                                                    Date:</b></Typography><Typography variant='body1' sx={{
                                                pl: 5,
                                                fontSize: 16, ...(fund.maturityChange ? {textDecoration: 'line-through'} : {})
                                            }}>{new Date(fund.maturity).toDateString()}</Typography>{fund.maturityChange &&
                                                <Typography variant='body1' sx={{
                                                    pl: 1,
                                                    fontSize: 16
                                                }}>{'  >  '}{fund.maturityChange && new Date(fund.maturityChange.amendedMaturity).toDateString()}</Typography>}
                                            </Grid>
                                            <Grid container item xs={12} sx={{px: 5, py: 1}} direction='row'>
                                                <Typography variant='subtitle1' sx={{color: 'primary.main'}}><b>Current
                                                    Allocation:</b></Typography><Typography variant='body1' sx={{
                                                pl: 5,
                                                fontSize: 16
                                            }}>{fCurrency(fund.commitment)}</Typography>
                                            </Grid>
                                        </Grid>
                                    }
                                    {/* Show Selldown and Repayments according to fund tranche */}
                                    {fund?.sellRepay.length > 0 &&
                                        <Grid container item xs={12}>
                                            <Grid item xs={12} sx={{px: 2, py: 1}}>
                                                <Typography variant='h5' sx={{color: 'primary.main'}}><b>Upcoming
                                                    Selldowns or Partial Repayments:</b></Typography>
                                                {fund.sellRepay.map((sr: any) => (
                                                    <Grid key={sr.id} sx={{bgcolor: 'grey.400'}}>
                                                        <Grid container item xs={12} sx={{px: 5, py: 1}}
                                                              direction='row'>
                                                            <Typography variant='subtitle1'
                                                                        sx={{color: 'primary.main'}}><b>Date:</b></Typography><Typography
                                                            variant='body1' sx={{
                                                            pl: 5,
                                                            fontSize: 16
                                                        }}>{new Date(sr.date).toDateString()}</Typography>
                                                        </Grid>
                                                        <Grid container item xs={12} sx={{px: 5, py: 1}}
                                                              direction='row'>
                                                            <Typography variant='subtitle1'
                                                                        sx={{color: 'primary.main'}}><b>Fund:</b></Typography><Typography
                                                            variant='body1'
                                                            sx={{pl: 5, fontSize: 16}}>{sr.fund}</Typography>
                                                        </Grid>
                                                        <Grid container item xs={12} sx={{px: 5, py: 1}}
                                                              direction='row'>
                                                            <Typography variant='subtitle1'
                                                                        sx={{color: 'primary.main'}}><b>Amount:</b></Typography><Typography
                                                            variant='body1' sx={{
                                                            pl: 5,
                                                            fontSize: 16
                                                        }}>{fCurrency(sr.amount)}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    }
                                    {/* Show transfers In according to fund tranche*/}
                                    {fund?.transfersIn.length > 0 &&
                                        <Grid container item xs={12}>
                                            <Grid item xs={12} sx={{px: 2, py: 1}}>
                                                <Typography variant='h5' sx={{color: 'primary.main'}}><b>Transfers
                                                    In:</b></Typography>
                                                {fund.transfersIn.map((t: any) => (
                                                    <Grid key={t.id} sx={{bgcolor: 'grey.400'}}>
                                                        <Grid container item xs={12} sx={{px: 5, py: 1}}
                                                              direction='row'>
                                                            <Typography variant='subtitle1'
                                                                        sx={{color: 'primary.main'}}><b>Date:</b></Typography><Typography
                                                            variant='body1' sx={{
                                                            pl: 5,
                                                            fontSize: 16
                                                        }}>{new Date(t.transferDate).toDateString()}</Typography>
                                                        </Grid>
                                                        <Grid container item xs={12} sx={{px: 5, py: 1}}
                                                              direction='row'>
                                                            <Typography variant='subtitle1'
                                                                        sx={{color: 'primary.main'}}><b>Transfer
                                                                From:</b></Typography><Typography variant='body1' sx={{
                                                            pl: 5,
                                                            fontSize: 16
                                                        }}>{t.fromFund}</Typography>
                                                        </Grid>
                                                        <Grid container item xs={12} sx={{px: 5, py: 1}}
                                                              direction='row'>
                                                            <Typography variant='subtitle1'
                                                                        sx={{color: 'primary.main'}}><b>Amount:</b></Typography><Typography
                                                            variant='body1' sx={{
                                                            pl: 5,
                                                            fontSize: 16
                                                        }}>{fCurrency(t.amount)}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    }
                                    {/* Show transfers Out according to fund tranche*/}
                                    {fund?.transfersOut.length > 0 &&
                                        <Grid container item xs={12}>
                                            <Grid item xs={12} sx={{px: 2, py: 1}}>
                                                <Typography variant='h5' sx={{color: 'primary.main'}}><b>Transfers
                                                    Out:</b></Typography>
                                                {fund.transfersOut.map((t: any) => (
                                                    <Grid key={t.id} sx={{bgcolor: 'grey.400'}}>
                                                        <Grid container item xs={12} sx={{px: 5, py: 1}}
                                                              direction='row'>
                                                            <Typography variant='subtitle1'
                                                                        sx={{color: 'primary.main'}}><b>Date:</b></Typography><Typography
                                                            variant='body1' sx={{
                                                            pl: 5,
                                                            fontSize: 16
                                                        }}>{new Date(t.transferDate).toDateString()}</Typography>
                                                        </Grid>
                                                        <Grid container item xs={12} sx={{px: 5, py: 1}}
                                                              direction='row'>
                                                            <Typography variant='subtitle1'
                                                                        sx={{color: 'primary.main'}}><b>Transfer To:</b></Typography><Typography
                                                            variant='body1'
                                                            sx={{pl: 5, fontSize: 16}}>{t.toFund}</Typography>
                                                        </Grid>
                                                        <Grid container item xs={12} sx={{px: 5, py: 1}}
                                                              direction='row'>
                                                            <Typography variant='subtitle1'
                                                                        sx={{color: 'primary.main'}}><b>Amount:</b></Typography><Typography
                                                            variant='body1' sx={{
                                                            pl: 5,
                                                            fontSize: 16
                                                        }}>{fCurrency(t.amount)}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    }
                                    <FormInput
                                        id='date'
                                        label='Selldown/Repayment Date'
                                        fieldType={InputTypes.DATE}
                                        layout={{xs: 12, md: 12, lg: 12}}
                                        minDate={(!!fund) ? new Date(fund.start_date) : new Date()}
                                        maxDate={(!!fund) ? new Date( fund.maturityChange?.amendedMaturity || fund.maturity) : undefined}
                                        disabled={!fund}
                                    />
                                    <FormInput
                                        id='amount'
                                        label='Selldown/Repayment Amount'
                                        fieldType={InputTypes.CURRENCY}
                                        layout={{xs: 12, md: 12, lg: 12}}
                                        disabled={!fund}
                                    />
                                    <Grid item container direction='row'>
                                        <Grid item sx={{width: '50%', p: 2}}>
                                            <Button
                                                fullWidth
                                                size="large"
                                                onClick={onClose}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item sx={{width: '50%', p: 2}}>
                                            <Button
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                            >
                                                {editValues?.id ?
                                                    'Update Selldown/Repayment' : 'Add Selldown/Repayment'
                                                }
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        </>
                    )
                }}
            </Formik>

        </>
    )
}